.header {
  width: 100%;
  padding: 30px 0;
  @include media("<=810px") {

  }
  @include media("<=430px") {
   padding-top: 20px;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
  position: relative;
  width: 100%;
  @include media("<=650px") {
    flex-wrap: wrap;
    row-gap: 20px;
  }
    
}

.header__nav {
  column-gap: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include media("<=650px") {
    justify-content: flex-start;
    gap: 20px;
  }
    @include media("<=450px") {
      
    }
}

.header__link {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s linear;
  @include media("<=450px") {
   
  }
  &:hover {
    

  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.12em;
  background: #000;
  bottom: -6px;
  left: 0;
  border-radius: 3px;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__logo {
  color: #000;
  text-decoration: none;
  font-size: 24px;
    font-weight: 700;
}
