.hero {
  position: relative;
  padding: 30px 0 100px;

  @include media("<=1080px") {

  }

  @include media("<=810px") {
    padding: 40px 0 120px;
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  

  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    
    align-items: center;
    
  }

  @include media("<=430px") {}
}

.hero__content {
  position: relative;
  z-index: 3;
  flex: 0 1 100%;
  width: 100%;
  max-width: 1104px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media("<=1280px") {}

  @include media("<=1080px") {}

  @include media("<=810px") {
    flex-direction: column;
    row-gap: 30px;
  }

  @include media("<=430px") {}
}

.hero__title {
  font-size: 0;
  color: transparent;
  width: 100%;
  position: relative;
  z-index: 3;
  max-width: 1104px;
  width: 100%;
  @include media("<=1280px") {
   
    
  }

  @include media("<=1080px") {
   

  }

  @include media("<=810px") {
   
    
  
  }

  @include media("<=430px") {
  

  }

  @include media("<=350px") {
    
  }
  img {
    width: 100%;
    display: block;
  }
}

.hero__subtitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  span {
    color: #C2E200;
  }
}

.hero__text {
  font-size: 14px;
    font-weight: 400;
   width: 100%;
   display: flex;
   gap: 20px;
   @include media("<=810px") {
    flex-wrap: wrap;

   }
    p {
      flex: 0 1 22%;
      @include media("<=810px") {
        flex: 0 1 48%;
      }
      @include media("<=550px") {
        flex: 0 1 100%;
      }
    }
}

.button {
  position: relative;
  display: flex;
  text-decoration: none;
  transition: all 0.3s linear;
 z-index: 3;
  width: 100%;
  max-width: 353px;
  @include media("<=430px") {

  }
  span {
    border-radius: 60px;
background: #C2E200;
padding: 20px;
text-align: center;
color: #000;
font-size: 16px;
font-weight: 800;
text-transform: uppercase;
text-decoration: none;
max-width: 293px;
width: 100%;
  }
  img {
    transition: all 0.3s linear;
  }
}

.button:hover img {
  transform: rotate(135deg);
  transform-origin: center center;
}
 


.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  max-width: 517px;
  @include media("<=810px") {
   
  }

  @include media("<=430px") {
    
  }

  img {
    display: block;
    width: 100%;
    @include media("<=810px") {
      width: 100%;
    }
  }
}