.info {
    padding: 0 0 100px;
    @include media("<=810px") {
      
    }
    .section__title {
        @include media("<=810px") {
            margin: 0 auto;
            width: fit-content;
        }
    }
}

.info__inner {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 37px;
    @include media("<=1080px") {
        column-gap: 10px;
    }
    @include media("<=750px") {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin: 0 auto;
    }
}


.info__text {
    max-width: 283px;
        font-size: 14px;
       line-height: 1.2;
        @include media("<=810px") {
   
        }
}

.arrow-r {
  max-width: 22px;
  width: 100%;
  @include media("<=750px") {
    transform: rotate(90deg);
    margin-left: 10px;
  }
}