.contacts {
   
    padding-bottom: 100px;
    @include media("<=810px") {
        padding-bottom: 60px;
    }
}

.contacts__title {
    font-size: 14px;
        font-weight: 500;
        padding: 20px 0 30px;
        line-height: 1.2;
}

.contacts__inner {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    align-items: center;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
}

.contacts__image {
    max-width: 210px;
    flex: 0 1 100%;
    img {
        width: 100%;
        display: block;
    }
}

.contacts__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 692px;
    flex: 0 1 100%;
    min-height: 100%;

    @include media("<=810px") {
        max-width: 525px;
        width: 100%;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    width: 100%;
    padding-top: 30px;
@include media("<=810px") {
   
}
}

.form__input {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    input {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.60);
        border-radius: 60px;
background: #FFF;
border: none;
        padding: 17px 20px;
    @include media("<=430px") {
        
    }
    }
    textarea {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.60);
        border-radius: 60px;
border: 1px solid #000;
        padding: 17px 20px;
        resize: none;
    @include media("<=430px") {
        
    }
    }
    
}

.form__checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    label {
        flex: 0 1 100%;
        width: 100%;
        max-width: 664px;
        font-size: 13px;
    }
}

.form__button {

    font-family: "Montserrat", sans-serif;
    background-color: transparent;
        border: none;
        
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            
        }
}

