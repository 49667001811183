.footer {
  padding: 0 0 30px;
  text-align: center;
  position: relative;
  @include media("<=810px") {
    overflow: visible;
    padding: 30px 0;
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.footer__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.copyright {
  width: 100%;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
  opacity: 0.7;
  @include media("<=430px") {
    
  }
}