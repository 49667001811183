.about {
  overflow-x: clip;
  position: relative;
  padding-bottom: 100px;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
  }
  @include media("<=430px") {
    
  }
  .section__title {
    color: #fff;
  }
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
overflow: hidden;
  @include media("<=810px") {
    flex-direction: column;
    gap: 30px;
  }
  @include media("<=560px") {
     
  }
}

.about__content {
  max-width: 601px;
  width: 100%;
}

.about__text {
  max-width: 833px;
  font-size: 14px;
  line-height: 1.2;
  padding-top: 30px;
  
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
   
  } 
  p {
    padding-top: 20px;
    @include media("<=810px") {
      
    }
  }
}

.about__image {
  max-width: 448px;
  width: 100%;
  @include media("<=560px") {

  }
}






