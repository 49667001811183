.features {
    padding: 0 0 100px;
}

.features__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px;
    @include media("<=840px") {
       gap: 20px;
       flex-direction: column;
       align-self: center;
    }
}

.features__item {
    display: flex;
    align-items: center;
    gap: 30px;
    min-height: 126px;
    flex: 0 1 49%;
    color: #0E0E0E;
  
    border-radius: 28px;
background: #fff;
padding: 30px;
@include media("<=1080px") {
    flex: 0 1 48%;
}
@include media("<=840px") {
    flex: 0 1 100%;
    align-items: flex-start;
}
@include media("<=530px") {
    flex: 0 1 100%;
    padding: 30px 15px;
    gap: 20px;
}
    p {
        
    }
    img {
        max-width: 50px;
        width: 100%;
    }
}



.features__title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    text-transform: uppercase;
    ul {
        font-size: 14px;
    }
}

.features__text {
    font-size: 14px;
    max-width: 381px;
}