body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background: #0E0E0E;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}



.section__title {

  font-size: 50px;
  font-weight: 800;
  text-transform: uppercase;
  max-width: 1104px;
  position: relative;
  column-gap: 10px;
  @include media("<=1080px") {
    font-size: 48px;
  }
  @include media("<=810px") {
   
  }
  @include media("<=450px") {
    font-size: 26px;
  }
  @include media("<=350px") {
    
  }
  span {
    color: #C2E200;
  }
}

.arrow-w {
  @include media("<=500px") {
    display: none;
  }
}

.page__title {
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 40px;
  @include media("<=810px") {
    font-size: 42px;
  }
}

.page__content {
  padding: 70px 0 100px;
  font-size: 14px;
  line-height: 1.2;
  strong {
    font-weight: 600;
    display: block;
    padding-top: 18px;
  }
}



.page__text {
  line-height: 1.2;
  font-size: 18px;
  font-weight: 500;
  p {
    padding-top: 18px;
  }
}


